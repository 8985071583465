import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ProgramYearResponse } from "api";
import { useProgramYearService } from "contexts/ServiceProvider";
import { Program, programs } from "constants/programs";

export const GET_PROGRAM_YEARS_QUERY_KEY = "program-years";

const useGetProgramYears = (
  program: Program,
  options?: Partial<UseQueryOptions<Array<ProgramYearResponse>>>
) => {
  const programYearService = useProgramYearService();

  const apiProgramKey = programs[program].api;

  return useQuery({
    queryKey: [GET_PROGRAM_YEARS_QUERY_KEY, apiProgramKey],
    queryFn: () =>
      programYearService.getProgramYears({
        program: apiProgramKey,
      }),
    ...options,
  });
};

export default useGetProgramYears;
