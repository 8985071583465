import AuthService from "services/AuthService";
import CropYearService from "services/CropYearService";
import CsvService from "services/CsvService";
import DailyPricingService from "services/DailyPricingService";
import DairyService from "services/DairyService";
import FarmService from "services/FarmService";
import FeatureFlagService from "services/FeatureFlagService";
import FieldService from "services/FieldService";
import FuturesService from "services/FuturesService";
import NotificationService from "services/NotificationService";
import PricingOptionService from "services/PricingOptionService";
import ProfilePictureService from "services/ProfilePictureService";
import ProgramYearService from "services/ProgramYearService";
import RegionService from "services/RegionService";
import UserService from "services/UserService";

export const services = {
  authService: new AuthService(),
  cropYearService: new CropYearService(),
  csvService: new CsvService(),
  dailyPricingService: new DailyPricingService(),
  dairyService: new DairyService(),
  farmService: new FarmService(),
  featureFlagService: new FeatureFlagService(),
  fieldService: new FieldService(),
  futuresService: new FuturesService(),
  notificationService: new NotificationService(),
  pricingOptionService: new PricingOptionService(),
  profilePictureService: new ProfilePictureService(),
  programYearService: new ProgramYearService(),
  regionService: new RegionService(),
  userService: new UserService(),
} as const;

export const useAuthService = (): AuthService => services.authService;
export const useCropYearService = (): CropYearService =>
  services.cropYearService;
export const useCsvService = (): CsvService => services.csvService;
export const useDailyPricingService = (): DailyPricingService =>
  services.dailyPricingService;
export const useDairyService = (): DairyService => services.dairyService;
export const useFarmService = (): FarmService => services.farmService;
export const useFeatureFlagService = (): FeatureFlagService =>
  services.featureFlagService;
export const useFieldService = (): FieldService => services.fieldService;
export const useFuturesService = (): FuturesService => services.futuresService;
export const useNotificationService = (): NotificationService =>
  services.notificationService;
export const usePricingOptionService = (): PricingOptionService =>
  services.pricingOptionService;
export const useProfilePictureService = (): ProfilePictureService =>
  services.profilePictureService;
export const useProgramYearService = (): ProgramYearService =>
  services.programYearService;
export const useRegionService = (): RegionService => services.regionService;
export const useUserService = (): UserService => services.userService;
