/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { DataListFarmListDto } from "../models";
// @ts-ignore
import { DataListProgramYearResponse } from "../models";
// @ts-ignore
import { DataProgramYearResponse } from "../models";
// @ts-ignore
import { PageResponseFarmDocumentDto } from "../models";
// @ts-ignore
import { PageResponseProgramYearFarmResponse } from "../models";
/**
 * ProgramYearApi - axios parameter creator
 * @export
 */
export const ProgramYearApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmsToProgramYear: async (
      id: string,
      requestBody: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("assignFarmsToProgramYear", "id", id);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("assignFarmsToProgramYear", "requestBody", requestBody);
      const localVarPath = `/program-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgramYear: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getProgramYear", "id", id);
      const localVarPath = `/program-year/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgramYearFarmDocumentsPage: async (
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getProgramYearFarmDocumentsPage", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getProgramYearFarmDocumentsPage", "farmId", farmId);
      const localVarPath = `/program-year/{id}/farm/{farm-id}/documents`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"farm-id"}}`, encodeURIComponent(String(farmId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId2 !== undefined && farmId2 !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId2);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFarmsNotAssignedToProgramYear: async (
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listFarmsNotAssignedToProgramYear", "id", id);
      const localVarPath = `/program-year/{id}/addable-farms`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProgramYearFarms: async (
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listProgramYearFarms", "id", id);
      const localVarPath = `/program-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'SILAGE' | 'CORN_SILAGE' | 'DRY_CORN' | 'HAY' | 'HAYLAGE' | 'MANURE' | 'WHEATLAGE'} program
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProgramYears: async (
      program:
        | "SILAGE"
        | "CORN_SILAGE"
        | "DRY_CORN"
        | "HAY"
        | "HAYLAGE"
        | "MANURE"
        | "WHEATLAGE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'program' is not null or undefined
      assertParamExists("listProgramYears", "program", program);
      const localVarPath = `/program-year/list/{program}`.replace(
        `{${"program"}}`,
        encodeURIComponent(String(program))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProgramYearApi - functional programming interface
 * @export
 */
export const ProgramYearApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ProgramYearApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignFarmsToProgramYear(
      id: string,
      requestBody: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.assignFarmsToProgramYear(
          id,
          requestBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProgramYear(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataProgramYearResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramYear(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProgramYearFarmDocumentsPage(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseFarmDocumentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProgramYearFarmDocumentsPage(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listFarmsNotAssignedToProgramYear(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataListFarmListDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listFarmsNotAssignedToProgramYear(
          id,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listProgramYearFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseProgramYearFarmResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listProgramYearFarms(
          id,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {'SILAGE' | 'CORN_SILAGE' | 'DRY_CORN' | 'HAY' | 'HAYLAGE' | 'MANURE' | 'WHEATLAGE'} program
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listProgramYears(
      program:
        | "SILAGE"
        | "CORN_SILAGE"
        | "DRY_CORN"
        | "HAY"
        | "HAYLAGE"
        | "MANURE"
        | "WHEATLAGE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataListProgramYearResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listProgramYears(
          program,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ProgramYearApi - factory interface
 * @export
 */
export const ProgramYearApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProgramYearApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmsToProgramYear(
      id: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .assignFarmsToProgramYear(id, requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgramYear(
      id: string,
      options?: any
    ): AxiosPromise<DataProgramYearResponse> {
      return localVarFp
        .getProgramYear(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgramYearFarmDocumentsPage(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseFarmDocumentDto> {
      return localVarFp
        .getProgramYearFarmDocumentsPage(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFarmsNotAssignedToProgramYear(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<DataListFarmListDto> {
      return localVarFp
        .listFarmsNotAssignedToProgramYear(id, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProgramYearFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseProgramYearFarmResponse> {
      return localVarFp
        .listProgramYearFarms(id, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {'SILAGE' | 'CORN_SILAGE' | 'DRY_CORN' | 'HAY' | 'HAYLAGE' | 'MANURE' | 'WHEATLAGE'} program
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProgramYears(
      program:
        | "SILAGE"
        | "CORN_SILAGE"
        | "DRY_CORN"
        | "HAY"
        | "HAYLAGE"
        | "MANURE"
        | "WHEATLAGE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<DataListProgramYearResponse> {
      return localVarFp
        .listProgramYears(program, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for assignFarmsToProgramYear operation in ProgramYearApi.
 * @export
 * @interface ProgramYearApiAssignFarmsToProgramYearRequest
 */
export interface ProgramYearApiAssignFarmsToProgramYearRequest {
  /**
   *
   * @type {string}
   * @memberof ProgramYearApiAssignFarmsToProgramYear
   */
  readonly id: string;

  /**
   *
   * @type {Array<string>}
   * @memberof ProgramYearApiAssignFarmsToProgramYear
   */
  readonly requestBody: Array<string>;
}

/**
 * Request parameters for getProgramYear operation in ProgramYearApi.
 * @export
 * @interface ProgramYearApiGetProgramYearRequest
 */
export interface ProgramYearApiGetProgramYearRequest {
  /**
   *
   * @type {string}
   * @memberof ProgramYearApiGetProgramYear
   */
  readonly id: string;
}

/**
 * Request parameters for getProgramYearFarmDocumentsPage operation in ProgramYearApi.
 * @export
 * @interface ProgramYearApiGetProgramYearFarmDocumentsPageRequest
 */
export interface ProgramYearApiGetProgramYearFarmDocumentsPageRequest {
  /**
   *
   * @type {string}
   * @memberof ProgramYearApiGetProgramYearFarmDocumentsPage
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof ProgramYearApiGetProgramYearFarmDocumentsPage
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof ProgramYearApiGetProgramYearFarmDocumentsPage
   */
  readonly farmId2?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ProgramYearApiGetProgramYearFarmDocumentsPage
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ProgramYearApiGetProgramYearFarmDocumentsPage
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ProgramYearApiGetProgramYearFarmDocumentsPage
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listFarmsNotAssignedToProgramYear operation in ProgramYearApi.
 * @export
 * @interface ProgramYearApiListFarmsNotAssignedToProgramYearRequest
 */
export interface ProgramYearApiListFarmsNotAssignedToProgramYearRequest {
  /**
   *
   * @type {string}
   * @memberof ProgramYearApiListFarmsNotAssignedToProgramYear
   */
  readonly id: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ProgramYearApiListFarmsNotAssignedToProgramYear
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ProgramYearApiListFarmsNotAssignedToProgramYear
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ProgramYearApiListFarmsNotAssignedToProgramYear
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listProgramYearFarms operation in ProgramYearApi.
 * @export
 * @interface ProgramYearApiListProgramYearFarmsRequest
 */
export interface ProgramYearApiListProgramYearFarmsRequest {
  /**
   *
   * @type {string}
   * @memberof ProgramYearApiListProgramYearFarms
   */
  readonly id: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ProgramYearApiListProgramYearFarms
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ProgramYearApiListProgramYearFarms
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ProgramYearApiListProgramYearFarms
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listProgramYears operation in ProgramYearApi.
 * @export
 * @interface ProgramYearApiListProgramYearsRequest
 */
export interface ProgramYearApiListProgramYearsRequest {
  /**
   *
   * @type {'SILAGE' | 'CORN_SILAGE' | 'DRY_CORN' | 'HAY' | 'HAYLAGE' | 'MANURE' | 'WHEATLAGE'}
   * @memberof ProgramYearApiListProgramYears
   */
  readonly program:
    | "SILAGE"
    | "CORN_SILAGE"
    | "DRY_CORN"
    | "HAY"
    | "HAYLAGE"
    | "MANURE"
    | "WHEATLAGE";

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ProgramYearApiListProgramYears
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ProgramYearApiListProgramYears
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ProgramYearApiListProgramYears
   */
  readonly sort?: Array<string>;
}

/**
 * ProgramYearApi - object-oriented interface
 * @export
 * @class ProgramYearApi
 * @extends {BaseAPI}
 */
export class ProgramYearApi extends BaseAPI {
  /**
   *
   * @param {ProgramYearApiAssignFarmsToProgramYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramYearApi
   */
  public assignFarmsToProgramYear(
    requestParameters: ProgramYearApiAssignFarmsToProgramYearRequest,
    options?: AxiosRequestConfig
  ) {
    return ProgramYearApiFp(this.configuration)
      .assignFarmsToProgramYear(
        requestParameters.id,
        requestParameters.requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProgramYearApiGetProgramYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramYearApi
   */
  public getProgramYear(
    requestParameters: ProgramYearApiGetProgramYearRequest,
    options?: AxiosRequestConfig
  ) {
    return ProgramYearApiFp(this.configuration)
      .getProgramYear(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProgramYearApiGetProgramYearFarmDocumentsPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramYearApi
   */
  public getProgramYearFarmDocumentsPage(
    requestParameters: ProgramYearApiGetProgramYearFarmDocumentsPageRequest,
    options?: AxiosRequestConfig
  ) {
    return ProgramYearApiFp(this.configuration)
      .getProgramYearFarmDocumentsPage(
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.farmId2,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProgramYearApiListFarmsNotAssignedToProgramYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramYearApi
   */
  public listFarmsNotAssignedToProgramYear(
    requestParameters: ProgramYearApiListFarmsNotAssignedToProgramYearRequest,
    options?: AxiosRequestConfig
  ) {
    return ProgramYearApiFp(this.configuration)
      .listFarmsNotAssignedToProgramYear(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProgramYearApiListProgramYearFarmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramYearApi
   */
  public listProgramYearFarms(
    requestParameters: ProgramYearApiListProgramYearFarmsRequest,
    options?: AxiosRequestConfig
  ) {
    return ProgramYearApiFp(this.configuration)
      .listProgramYearFarms(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProgramYearApiListProgramYearsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramYearApi
   */
  public listProgramYears(
    requestParameters: ProgramYearApiListProgramYearsRequest,
    options?: AxiosRequestConfig
  ) {
    return ProgramYearApiFp(this.configuration)
      .listProgramYears(
        requestParameters.program,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
