import { useEffect } from "react";
import { Center, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useOutletContext,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";

import { CSVUploadJobResponse, CSVUploadJobResponseStatusEnum } from "api";
import { CsvStep, UploadProgressSpinner } from "components/CsvUpload";
import ErrorModal from "components/CsvUpload/ErrorModal";
import UploadBox from "components/UploadBox";
import { PROGRAM_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY } from "hooks/api/program-year/useGetProgramYearFarmDocuments";
import useCsvUploadJob from "hooks/csv-upload/useCsvUploadJob";
import useCustomToast from "hooks/useCustomToast";
import adminPaths from "routes/admin/adminPaths";
import { csvChildPaths } from "routes/csv-uploads/csvPaths";

const FarmDocumentsCsvUploadProgress = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { onSuccessToast } = useCustomToast();
  const [, setFile] =
    useOutletContext<[File, (file: File | undefined) => void]>();
  const { jobId } = useParams();
  const queryClient = useQueryClient();

  const onSuccess = (data: CSVUploadJobResponse) => {
    if (
      data.status === CSVUploadJobResponseStatusEnum.Success &&
      data.itemsWithErrors === 0
    ) {
      queryClient
        .invalidateQueries({
          queryKey: [PROGRAM_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY],
        })
        .catch((error) => {
          console.error("invalidate query", error);
        });

      setFile(undefined);
      navigate(`/${adminPaths.basePath}/${adminPaths.children.farms}`);
      onSuccessToast({ message: "Successfully uploaded" });
    }
  };

  const { data, isPending } = useCsvUploadJob(jobId, onSuccess);

  useEffect(() => {
    if (
      data?.status === CSVUploadJobResponseStatusEnum.Success &&
      (data?.itemsWithErrors ?? 0) > 0
    ) {
      onOpen();
    }
  }, [data, onOpen]);

  const isComplete = data?.status === CSVUploadJobResponseStatusEnum.Success;
  const isFailed = data?.status === CSVUploadJobResponseStatusEnum.Failed;
  const jobInProgress =
    isPending || data?.status === CSVUploadJobResponseStatusEnum.InProgress;

  if (isFailed) {
    return <Navigate to={`../${csvChildPaths.failed}/${jobId}`} />;
  }

  return (
    <>
      {isComplete && (data?.itemsWithErrors ?? 0) > 0 && (
        <ErrorModal
          isOpen={isOpen}
          onClose={onClose}
          rowErrors={data.allRowErrors}
        />
      )}
      <CsvStep
        currentStep={2}
        totalSteps={2}
        description="Checking for errors"
        backButtonHidden={true}
      />
      <UploadBox h="unset" py="134px" bg="gray.50" borderColor="gray.200">
        <Center>
          <Stack alignItems="center">
            <Text fontWeight="bold" color="steelGray">
              Uploading...
            </Text>
            <UploadProgressSpinner isLoading={jobInProgress} />
            <Text fontWeight="bold">
              Please wait while we check for errors in your data.
            </Text>
          </Stack>
        </Center>
      </UploadBox>
    </>
  );
};

export default FarmDocumentsCsvUploadProgress;
