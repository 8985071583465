import { ButtonProps } from "@chakra-ui/react";
import { useNavigationRoute } from "hooks/useNavigationRoute";
import { UsersIcon } from "icons";
import { ReactElement } from "react";
import NavigationItem from "./NavigationItem";

export const UsersNavigationItem = (props: ButtonProps): ReactElement => {
  const { usersRoute } = useNavigationRoute();

  return (
    <NavigationItem icon={UsersIcon} route={usersRoute} {...props}>
      Users
    </NavigationItem>
  );
};
