import { ReactElement } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { ButtonProps } from "@chakra-ui/react";

import { FarmsIcon } from "icons";
import farmPaths from "routes/farm/farmPaths";
import NavigationItem from "./NavigationItem";
import useSelectedFarmId from "hooks/useSelectedFarmId";

export const FarmInfoNavigationItem = (
  props: ButtonProps
): ReactElement | null => {
  const [farmId] = useSelectedFarmId();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isDocumentsRoute =
    pathname.includes(`${farmPaths.children.documents}`) &&
    pathname.includes(farmId ?? "");

  return farmId ? (
    <NavigationItem
      icon={FarmsIcon}
      route={
        isDocumentsRoute
          ? `/${farmPaths.basePath}/${farmId}/${farmPaths.children.documents}?${searchParams}`
          : `/${farmPaths.basePath}/${farmId}/${farmPaths.children.edit}?${searchParams}`
      }
      {...props}
    >
      Farm Information
    </NavigationItem>
  ) : null;
};
