import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useBreakpointValue } from "@chakra-ui/react";

import FarmListTable from "components/FarmListTable/FarmListTable";
import {
  baseListColumns,
  desktopListColumns,
  farmListColumnsWithSecondarySort,
  farmListDefaultSort,
  FarmsSortColumns,
  listTableDataMapper,
} from "components/FarmListTable/listTableDataMapper";
import { TableData } from "components/SortableTable";
import { Program, programs } from "constants/programs";
import useGetProgramYearFarms from "hooks/api/program-year/useGetProgramYearFarms";
import useSelectedProgramYearId from "hooks/useSelectedProgramYearId";
import useSort, { addSecondarySort } from "hooks/useSort";
import documentProgramPaths from "routes/document-programs/documentProgramPaths";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const tableColumns = mobileStyleBreakpoint(
  baseListColumns,
  desktopListColumns,
  "md"
);

type DocumentProgramFarmListTableProps = Readonly<{ program: Program }>;

const DocumentProgramFarmListTable = ({
  program,
}: DocumentProgramFarmListTableProps) => {
  const { title } = programs[program];

  const columns = useBreakpointValue(tableColumns);
  const navigate = useNavigate();
  const [programYearId] = useSelectedProgramYearId();

  const useSortProps = useSort<FarmsSortColumns>(farmListDefaultSort);
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetProgramYearFarms(programYearId, {
      sort: addSecondarySort(
        useSortProps.sort,
        farmListColumnsWithSecondarySort
      ),
    });

  const farms = useMemo(
    () => data?.pages?.map(({ data }) => data).flat() ?? [],
    [data]
  );
  const tableData = useMemo(() => listTableDataMapper(farms), [farms]);

  const onClickRow = useCallback(
    async ({ farmId }: TableData) => {
      navigate(
        documentProgramPaths.fullPaths.farmDocuments(
          program,
          programYearId ?? "",
          farmId
        )
      );
    },
    [navigate, programYearId, program]
  );

  return (
    <FarmListTable
      programType={title}
      isLoading={isLoading}
      columns={columns}
      tableData={tableData}
      defaultSort={farmListDefaultSort}
      onClickRow={onClickRow}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      {...useSortProps}
    />
  );
};

export default DocumentProgramFarmListTable;
