import { ButtonProps } from "@chakra-ui/react";
import { useNavigationRoute } from "hooks/useNavigationRoute";
import { DashboardIcon } from "icons";
import { ReactElement } from "react";
import NavigationItem from "./NavigationItem";

export const SilageDashboardNavigationItem = (
  props: ButtonProps
): ReactElement => {
  const { dashboardRoute } = useNavigationRoute();

  return (
    <NavigationItem icon={DashboardIcon} route={dashboardRoute} {...props}>
      Silage
    </NavigationItem>
  );
};
