import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { useProgramYearService } from "contexts/ServiceProvider";
import { getNextPage, QueryParams } from "services/apiHelpers";
import { FarmsSortColumns } from "components/FarmListTable/listTableDataMapper";

export const PROGRAM_YEAR_FARMS_QUERY_KEY = "list-program-year-farms";

const useGetProgramYearFarms = (
  programYearId?: string,
  queryParams?: Omit<QueryParams<FarmsSortColumns>, "page">
) => {
  const service = useProgramYearService();

  return useInfiniteQuery({
    queryKey: [PROGRAM_YEAR_FARMS_QUERY_KEY, programYearId, queryParams],
    queryFn: programYearId
      ? ({ pageParam = 0 }) =>
          service.getProgramYearFarms(programYearId, {
            ...queryParams,
            page: pageParam,
            size: 10,
          })
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage),
  });
};

export default useGetProgramYearFarms;
