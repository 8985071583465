import {
  createContext,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { Navigate } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";

import { ProgramYearResponse } from "api";
import { Program } from "constants/programs";
import useGetProgramYears from "hooks/api/program-year/useGetProgramYears";
import useAccessToken from "hooks/auth/useAccessToken";
import useSelectedProgramYearId, {
  PROGRAM_YEAR_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedProgramYearId";
import useSelectedFarmId, {
  FARM_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedFarmId";

type ProgramYearContextValue = {
  program: Program;
  programYear: ProgramYearResponse;
};

export const ProgramYearContext = createContext<
  ProgramYearContextValue | undefined
>(undefined);

export const useProgramYear = () => {
  const context = useContext(ProgramYearContext);
  if (context === undefined) {
    throw new Error("Must be used within ProgramYearProvider");
  }

  return context;
};

const ProgramYearContextProvider = ({
  programYear,
  program,
  children,
}: ProgramYearContextValue & PropsWithChildren) => {
  return (
    <ProgramYearContext.Provider
      value={useMemo(() => ({ programYear, program }), [programYear, program])}
    >
      {children}
    </ProgramYearContext.Provider>
  );
};

type ProgramYearProviderProps = Readonly<{
  children: ReactNode;
  program: Program;
}>;

const ProgramYearProvider = ({
  program,
  children,
}: ProgramYearProviderProps): ReactElement => {
  const { accessToken } = useAccessToken();
  const [programYearId] = useSelectedProgramYearId();
  const [farmId] = useSelectedFarmId();

  const { data, isLoading } = useGetProgramYears(program, {
    enabled: !!accessToken,
  });

  const selectedProgramYear = useMemo(
    () => data?.find((programYear) => programYear.id === programYearId),
    [data, programYearId]
  );

  if (isLoading || !data) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    );
  }

  if (!selectedProgramYear) {
    const urlParams = new URLSearchParams();
    // set the programYearId to a default of the first available program year
    urlParams.set(PROGRAM_YEAR_ID_QUERY_STRING_KEY, data[0].id);

    // and also keep the farmId parameter if it was set to avoid switching the user's selected farm
    if (farmId) {
      urlParams.set(FARM_ID_QUERY_STRING_KEY, farmId);
    }

    return <Navigate replace to={`?${urlParams}`} />;
  }

  return (
    <ProgramYearContextProvider
      program={program}
      programYear={selectedProgramYear}
    >
      {children}
    </ProgramYearContextProvider>
  );
};

export default ProgramYearProvider;
