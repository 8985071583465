import { FarmDetailResponseInProgramsEnum } from "api";

export const programs = {
  // fully featured programs
  silage: {
    slug: "silage",
    title: "Silage",
    api: "SILAGE",
    inProgramEnum: FarmDetailResponseInProgramsEnum.Silage,
  },

  // document only programs
  cornSilage: {
    slug: "corn-silage",
    title: "Corn Silage",
    api: "CORN_SILAGE",
    inProgramEnum: FarmDetailResponseInProgramsEnum.CornSilage,
  },
  dryCorn: {
    slug: "dry-corn",
    title: "Dry Corn",
    api: "DRY_CORN",
    inProgramEnum: FarmDetailResponseInProgramsEnum.DryCorn,
  },
  hay: {
    slug: "hay",
    title: "Hay",
    api: "HAY",
    inProgramEnum: FarmDetailResponseInProgramsEnum.Hay,
  },
  haylage: {
    slug: "haylage",
    title: "Haylage",
    api: "HAYLAGE",
    inProgramEnum: FarmDetailResponseInProgramsEnum.Haylage,
  },
  manure: {
    slug: "manure",
    title: "Manure",
    api: "MANURE",
    inProgramEnum: FarmDetailResponseInProgramsEnum.Manure,
  },
  wheatlage: {
    slug: "wheatlage",
    title: "Wheatlage",
    api: "WHEATLAGE",
    inProgramEnum: FarmDetailResponseInProgramsEnum.Wheatlage,
  },
} as const;

export type Program = keyof typeof programs;

export const programKeys = Object.keys(programs) as Program[];

export const documentProgramKeys = programKeys.filter(
  (key) => key !== "silage"
);
