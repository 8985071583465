import { useCallback, useMemo } from "react";
import {
  AddableFarmsSortColumns,
  addFarmsTableColumns,
  baseSelectFarmsListColumns,
  desktopFarmsListColumns,
} from "components/AddFarmsToYear/addFarmsTableColumns";
import AddFarmsPageLayout from "components/AddFarmsToYear/AddFarmsToYearPageLayout";
import { programs } from "constants/programs";
import { useProgramYear } from "contexts/ProgramYearProvider";
import useGetAddableFarmsForProgramYear from "hooks/api/program-year/useGetAddableFarmsForProgramYear";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useSelectionIds from "hooks/useSelectionIds";
import useSort, {
  addSecondarySort,
  ColumnsWithSecondarySort,
  DEFAULT_SORT_DIRECTION,
  Sort,
} from "hooks/useSort";
import documentProgramPaths from "routes/document-programs/documentProgramPaths";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import AddFarmsToProgramYearButton from "./AddFarmsToProgramYearButton";

const selectFarmsListTableColumns = mobileStyleBreakpoint(
  baseSelectFarmsListColumns,
  desktopFarmsListColumns,
  "md"
);

const columnsWithSecondarySort: ColumnsWithSecondarySort<AddableFarmsSortColumns> =
  {
    "region.name": {
      key: "doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
  };

const defaultSort: Sort<AddableFarmsSortColumns> = {
  key: "region.name",
  direction: DEFAULT_SORT_DIRECTION,
};

const AddDocumentProgramFarmsPage = () => {
  const { program, programYear } = useProgramYear();
  const useSortProps = useSort<AddableFarmsSortColumns>(defaultSort);
  const { data, isLoading } = useGetAddableFarmsForProgramYear(programYear.id, {
    sort: addSecondarySort(useSortProps.sort, columnsWithSecondarySort),
  });

  const navigate = useNavigateWithQuery();
  const navigateToAllFarmsList = useCallback(
    () =>
      navigate(
        documentProgramPaths.fullPaths.allFarms(program, programYear.id)
      ),
    [navigate, program, programYear.id]
  );

  const tableData = useMemo(
    () => addFarmsTableColumns(data?.flatMap((response) => response) ?? []),
    [data]
  );
  const { selectedIds, onToggleId, clearSelectedIds } = useSelectionIds(
    tableData.map((field: Record<string, any>) => field.id)
  );

  return (
    <AddFarmsPageLayout
      defaultSort={defaultSort}
      programType={programs[program].title}
      year={programYear.year}
      navigateToAllFarmsList={navigateToAllFarmsList}
      selectedIds={selectedIds}
      isLoading={isLoading}
      tableData={tableData}
      onToggleId={onToggleId}
      selectFarmsListTableColumns={selectFarmsListTableColumns}
      {...useSortProps}
    >
      <AddFarmsToProgramYearButton
        farmIds={selectedIds}
        isDisabled={selectedIds?.length === 0}
        onSuccess={clearSelectedIds}
      />
    </AddFarmsPageLayout>
  );
};

export default AddDocumentProgramFarmsPage;
