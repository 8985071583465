import { ComponentType, PropsWithChildren } from "react";

import { Button, ButtonProps, HStack, IconProps, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const NavigationItem = ({
  children,
  route,
  icon: Icon,
  ...rest
}: PropsWithChildren<ButtonProps> & {
  route: string;
  icon: ComponentType<IconProps>;
}) => (
  <NavLink to={route}>
    {({ isActive }) => (
      <Button {...rest} variant={isActive ? "activeNav" : "navigation"}>
        <HStack spacing={2} w="100%">
          <Icon boxSize={6} color="inherit" aria-hidden="true" />
          <Text color="inherit">{children}</Text>
        </HStack>
      </Button>
    )}
  </NavLink>
);

export default NavigationItem;
