import ProgramListPageLayout from "components/ProgramListPageLayout/ProgramListPageLayout";
import { programs } from "constants/programs";
import { useProgramYear } from "contexts/ProgramYearProvider";
import documentProgramPaths from "routes/document-programs/documentProgramPaths";
import DocumentProgramFarmListTable from "./DocumentProgramFarmListTable";
import ProgramYearSelect from "./ProgramYearSelect";

const DocumentProgramFarmListPage = () => {
  const { program, programYear } = useProgramYear();

  return (
    <ProgramListPageLayout
      heading={programs[program].title}
      pageTitle="All Farms"
      path={documentProgramPaths.fullPaths.addFarms(program, programYear.id)}
      yearSelect={<ProgramYearSelect />}
    >
      <DocumentProgramFarmListTable program={program} />
    </ProgramListPageLayout>
  );
};

export default DocumentProgramFarmListPage;
