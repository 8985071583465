import { ReactElement, ReactNode } from "react";
import { Link as RLink } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PageHeader, PageHeaderStack } from "components/page-components";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const SUB_HEADING_MARGIN_LEFT = mobileStyleBreakpoint("24px", "unset");

type ProgramListPageLayouProps = {
  heading: string;
  subHeading?: string;
  pageTitle: string;
  isLoading?: boolean;
  path?: string;
  yearSelect?: JSX.Element;
  displayAddFarmsButton?: boolean;
  children: ReactNode;
};

const ProgramListPageLayout = ({
  heading,
  subHeading,
  pageTitle,
  isLoading,
  path,
  yearSelect,
  displayAddFarmsButton = true,
  children,
}: ProgramListPageLayouProps): ReactElement => (
  <Box p={{ base: 2, md: 0 }}>
    {isLoading ? (
      <Center h="100%">
        <Spinner />
      </Center>
    ) : (
      <Stack spacing={5} h="100%">
        <PageHeaderStack>
          <PageHeader heading={heading} subHeading={subHeading}>
            {displayAddFarmsButton ? (
              <Button variant="primary" as={RLink} size="md" to={path}>
                Add Farms
              </Button>
            ) : null}
          </PageHeader>
          <Box maxW="132px">{yearSelect}</Box>
        </PageHeaderStack>
        <Stack spacing={1}>
          <Box marginLeft={SUB_HEADING_MARGIN_LEFT}>
            <Text textStyle="h6">{pageTitle}</Text>
          </Box>
          <Divider color="grayFactor.1.5" opacity={1} borderBottomWidth="2px" />
        </Stack>
        {children}
      </Stack>
    )}
  </Box>
);

export default ProgramListPageLayout;
