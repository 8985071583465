import { ComponentStyleConfig } from "@chakra-ui/react";

const constructButtonStyle = (
  bg: string,
  color: string,
  hover: any,
  focus: any,
  disabled: any
) => ({
  bg,
  color,
  _active: hover,
  _hover: {
    ...hover,
    _disabled: disabled,
  },
  _focus: focus,
  _focusWithin: focus,
  _focusVisible: focus,
  _disabled: disabled,
});

const outlineProps = {
  outlineWidth: "2px",
  outlineOffset: 0,
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    _disabled: {
      cursor: "default",
      opacity: 1,
      _hover: {
        cursor: "default",
      },
    },
    borderRadius: 4,
  },
  variants: {
    primary: ({ colorScheme }) => {
      const _disabled = {
        bg: `${colorScheme}.200`,
      };
      const _hover = {
        bg: `${colorScheme}.900`,
      };
      const _focus = {
        ...outlineProps,
        bg: `${colorScheme}.900`,
        outlineColor: `${colorScheme}.200`,
      };

      return constructButtonStyle(
        `${colorScheme}.800`,
        "white",
        _hover,
        _focus,
        _disabled
      );
    },
    secondary: ({ colorScheme }) => {
      const _disabled = {
        bg: `${colorScheme}.50`,
        color: `${colorScheme}.200`,
      };
      const _hover = {
        bg: `${colorScheme}.200`,
      };
      const _focus = {
        ...outlineProps,
        bg: `${colorScheme}.200`,
        outlineColor: `${colorScheme}.800`,
      };

      return constructButtonStyle(
        `${colorScheme}.50`,
        `${colorScheme}.800`,
        _hover,
        _focus,
        _disabled
      );
    },
    destructive: () => {
      const _disabled = {
        bg: "error.50",
        color: "error.200",
      };
      const _hover = {
        bg: "error.700",
      };
      const _focus = {
        ...outlineProps,
        bg: "error.700",
        outlineColor: `errorRed`,
      };

      return constructButtonStyle(
        "errorRed",
        "white",
        _hover,
        _focus,
        _disabled
      );
    },
    outline: ({ colorScheme }) => {
      const _disabled = {
        bg: "unset",
        color: `${colorScheme}.200`,
        border: "1px solid",
      };
      const _hover = {
        bg: `${colorScheme}.50`,
        border: "1px solid",
      };
      const _focus = {
        ...outlineProps,
        bg: `${colorScheme}.50`,
        outlineColor: `${colorScheme}.800`,
      };

      return constructButtonStyle(
        "unset",
        `${colorScheme}.800`,
        _hover,
        _focus,
        _disabled
      );
    },
    ghost: ({ colorScheme }) => {
      const _disabled = {
        bg: "unset",
        color: `${colorScheme}.200`,
      };
      const _hover = {
        bg: `${colorScheme}.50`,
      };
      const _focus = {
        ...outlineProps,
        bg: `${colorScheme}.50`,
        outlineColor: `${colorScheme}.800`,
      };

      return constructButtonStyle(
        "unset",
        `${colorScheme}.800`,
        _hover,
        _focus,
        _disabled
      );
    },
    tableButton: (props) => {
      return {
        ...props.theme.components.Button.variants.link,
        iconSpacing: "5px",
        color: "brand.800",
        fontWeight: "normal",
        fontSize: "sm",
        h: "21px",
        p: 0,
        lineHeight: "21px",
        _disabled: {
          opacity: "1",
          color: "grayFactor.2",
        },
      };
    },
    navigation: {
      fontWeight: "bold",
      borderRadius: "lg",
      h: "64px",
      w: "100%",
      _hover: {
        color: "brand.800",
        bgColor: "grayFactor.1.5",
      },
    },
    activeNav: {
      fontWeight: "bold",
      borderRadius: "lg",
      h: "64px",
      w: "100%",
      color: "brand.800",
      bgColor: "grayFactor.1.5",
    },
    datePicker: {
      boxSize: 8,
      minW: "unset",
      bgColor: "white",
      borderRadius: "md",
      border: "1px solid",
      borderColor: "grayFactor.1.5",
    },
  },
  sizes: {
    lg: {
      fontSize: "md",
    },
  },
  defaultProps: {
    variant: "primary",
    size: "lg",
  },
};

export default Button;
