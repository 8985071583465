import { useQuery } from "@tanstack/react-query";

import { FarmListDto } from "api";
import { AddableFarmsSortColumns } from "components/AddFarmsToYear/addFarmsTableColumns";
import { useProgramYearService } from "contexts/ServiceProvider";
import { BaseApiError, QueryParams } from "services/apiHelpers";

export const GET_PROGRAM_YEAR_ADDABLE_FARMS_QUERY_KEY =
  "addable-farms-program-year";

const useGetAddableFarmsForProgramYear = (
  programYearId?: string,
  sort?: Omit<QueryParams<AddableFarmsSortColumns>, "page">
) => {
  const service = useProgramYearService();

  return useQuery<FarmListDto[], BaseApiError>({
    queryKey: [GET_PROGRAM_YEAR_ADDABLE_FARMS_QUERY_KEY, programYearId, sort],
    queryFn: () =>
      programYearId
        ? service.getAddableFarmsForProgramYear(programYearId, sort)
        : Promise.reject(new Error("Must have programYearId")),
  });
};

export default useGetAddableFarmsForProgramYear;
