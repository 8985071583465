import { ReactElement, useMemo } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { FarmDetailResponse, FarmDetailResponseInProgramsEnum } from "api";
import FarmMenu from "components/navigation/FarmMenu";
import RiverviewEmployeeCard from "components/navigation/RiverviewEmployeeCard";
import { SITE_HEADER_HEIGHT } from "components/SiteHeader";
import { documentProgramKeys, programs } from "constants/programs";
import { useUserInfo } from "contexts/UserInfoProvider";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useSelectedFarmDetail from "hooks/useSelectedFarmDetail";
import { isFarmPending } from "utils/farmStatusUtils";
import NavigationHelp from "./NavigationHelp/NavigationHelp";
import {
  DocumentProgramNavigationItem,
  FarmInfoNavigationItem,
  FarmsNavigationItem,
  SilageDashboardNavigationItem,
  UsersNavigationItem,
} from "./NavigationItem";
import NavigationGroupHeader from "./NavigationGroupHeader";

const NavigationBadge = ({
  isRiverviewEmployee,
}: {
  isRiverviewEmployee: boolean;
}): ReactElement =>
  isRiverviewEmployee ? <RiverviewEmployeeCard /> : <FarmMenu />;

const isInProgram = (
  farm: FarmDetailResponse | undefined,
  program: FarmDetailResponseInProgramsEnum
) => !!farm?.inPrograms?.find((inProgram) => inProgram === program);

const NavigationContainer = ({
  onClickItem,
}: {
  onClickItem?: () => void;
}): ReactElement => {
  const { isEmployee, isFarmer } = useUserRoleFlags();
  const { data: selectedFarm } = useSelectedFarmDetail();
  const isSelectedFarmPending =
    selectedFarm && isFarmPending(selectedFarm.status.value);
  const { farms } = useUserInfo();

  const hasValidFarm = isFarmer && !isSelectedFarmPending && farms.length !== 0;

  const documentPrograms = useMemo(() => {
    return documentProgramKeys.filter((program) => {
      // Riverview Employees see all document programs
      if (isEmployee) return true;

      // Farm must be setup
      if (!hasValidFarm) return false;

      // Farm must be in the program
      return isInProgram(selectedFarm, programs[program].inProgramEnum);
    });
  }, [selectedFarm, hasValidFarm, isEmployee]);

  return (
    <Stack
      bg="grayFactor.1"
      w="320px"
      minW="320px"
      pt={1}
      spacing={0}
      height={`calc(100vh - ${SITE_HEADER_HEIGHT})`}
      overflowY="auto"
    >
      <Stack p={4}>
        <NavigationBadge isRiverviewEmployee={isEmployee} />
        {(isEmployee ||
          (hasValidFarm &&
            isInProgram(
              selectedFarm,
              FarmDetailResponseInProgramsEnum.Silage
            ))) && <SilageDashboardNavigationItem onClick={onClickItem} />}

        {documentPrograms.length > 0 && (
          <>
            <NavigationGroupHeader>Documents</NavigationGroupHeader>

            {documentPrograms.map((program) => (
              <DocumentProgramNavigationItem key={program} program={program} />
            ))}
          </>
        )}

        {(isEmployee || hasValidFarm) && (
          <>
            <NavigationGroupHeader>Information</NavigationGroupHeader>

            {isEmployee && <FarmsNavigationItem onClick={onClickItem} />}
            {(isEmployee || hasValidFarm) && (
              <UsersNavigationItem onClick={onClickItem} />
            )}

            {isFarmer && <FarmInfoNavigationItem onClick={onClickItem} />}
          </>
        )}
      </Stack>

      <Flex
        px={4}
        height={"100%"}
        flexDirection={"column"}
        justifyContent={"flex-end"}
      >
        <NavigationHelp />
      </Flex>
    </Stack>
  );
};

export default NavigationContainer;
