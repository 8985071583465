import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useProgramYearService } from "contexts/ServiceProvider";
import { GET_PROGRAM_YEAR_ADDABLE_FARMS_QUERY_KEY } from "hooks/api/program-year/useGetAddableFarmsForProgramYear";
import { PROGRAM_YEAR_FARMS_QUERY_KEY } from "hooks/api/program-year/useGetProgramYearFarms";

const ASSIGN_FARM_TO_PROGRAM_YEAR_MUTATION_KEY = "assign-farm-to-program-year";

const useAssignFarmToHaylageYear = (programYearId?: string) => {
  const service = useProgramYearService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ASSIGN_FARM_TO_PROGRAM_YEAR_MUTATION_KEY, { programYearId }],
    mutationFn: (farmIds: Array<string>) =>
      programYearId && farmIds.length > 0
        ? service.assignFarmsToProgramYear({
            id: programYearId,
            requestBody: farmIds,
          })
        : Promise.reject(new Error("Must have programYearId and farmIds")),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [PROGRAM_YEAR_FARMS_QUERY_KEY],
        }),
        queryClient.invalidateQueries({
          queryKey: [GET_PROGRAM_YEAR_ADDABLE_FARMS_QUERY_KEY],
        }),
      ]),
  });
};

export default useAssignFarmToHaylageYear;
