import { ReactElement } from "react";
import { ButtonProps } from "@chakra-ui/react";
import NavigationItem from "./NavigationItem";
import { Program, programs } from "constants/programs";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useSelectedFarmDetail from "hooks/useSelectedFarmDetail";
import { FARM_ID_QUERY_STRING_KEY } from "hooks/useSelectedFarmId";
import { DocumentFilledIcon } from "icons";
import documentProgramPaths from "routes/document-programs/documentProgramPaths";

export const DocumentProgramNavigationItem = ({
  program,
  ...rest
}: Readonly<ButtonProps & { program: Program }>): ReactElement => {
  const { isEmployee } = useUserRoleFlags();
  const { data: selectedFarm } = useSelectedFarmDetail();

  const { slug, title } = programs[program];

  return (
    <NavigationItem
      icon={DocumentFilledIcon}
      route={
        isEmployee
          ? `/${slug}/${documentProgramPaths.children.allFarms}`
          : `/${slug}/${documentProgramPaths.children.farmDocuments}?${FARM_ID_QUERY_STRING_KEY}=${selectedFarm?.id}`
      }
      {...rest}
    >
      {title}
    </NavigationItem>
  );
};
