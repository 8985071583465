/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ProgramYearResponse
 */
export interface ProgramYearResponse {
  /**
   *
   * @type {string}
   * @memberof ProgramYearResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProgramYearResponse
   */
  program: ProgramYearResponseProgramEnum;
  /**
   *
   * @type {number}
   * @memberof ProgramYearResponse
   */
  year: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ProgramYearResponseProgramEnum {
  Silage = "SILAGE",
  CornSilage = "CORN_SILAGE",
  DryCorn = "DRY_CORN",
  Hay = "HAY",
  Haylage = "HAYLAGE",
  Manure = "MANURE",
  Wheatlage = "WHEATLAGE",
}
