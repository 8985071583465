import DocumentsList from "components/DocumentsList";
import FarmListPageLayout from "components/ProgramListPageLayout";
import { programs } from "constants/programs";
import { useProgramYear } from "contexts/ProgramYearProvider";
import useGetProgramYearFarmDocuments from "hooks/api/program-year/useGetProgramYearFarmDocuments";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useSelectedProgramYearId from "hooks/useSelectedProgramYearId";
import ProgramYearSelect from "./ProgramYearSelect";

const DocumentList = () => {
  const [programYearId] = useSelectedProgramYearId();
  const [farmId] = useSelectedFarmId();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetProgramYearFarmDocuments(programYearId, farmId);

  return (
    <DocumentsList
      allowDeletion={false}
      farmId={farmId}
      pages={data?.pages}
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};

const DocumentProgramFarmDocumentListPage = () => {
  const [selectedFarmId] = useSelectedFarmId();
  const { data, isLoading } = useFarmDetail(selectedFarmId);
  const { program } = useProgramYear();
  const { title } = programs[program];

  return (
    <FarmListPageLayout
      heading={title}
      subHeading={data?.doingBusinessAs}
      pageTitle="Documents"
      displayAddFarmsButton={false}
      isLoading={isLoading}
      yearSelect={<ProgramYearSelect />}
    >
      <DocumentList />
    </FarmListPageLayout>
  );
};

export default DocumentProgramFarmDocumentListPage;
