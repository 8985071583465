import { ReactElement } from "react";

import { Icon, IconProps } from "@chakra-ui/react";

import { FiEyeOff, FiEye, FiArrowRight } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiOutlineUpload } from "react-icons/hi";
import {
  IoCheckmarkCircle,
  IoAlertCircle,
  IoAlertCircleOutline,
  IoCloseSharp,
} from "react-icons/io5";
import { ImCheckmark } from "react-icons/im";

import { ReactComponent as AddIconSVG } from "assets/add.svg";
import { ReactComponent as CalendarIconSVG } from "assets/calendar.svg";
import { ReactComponent as CheckMarkIconSVG } from "assets/checkmark.svg";
import { ReactComponent as CloseIconSVG } from "assets/close.svg";
import { ReactComponent as DashboardIconSVG } from "assets/dashboard.svg";
import { ReactComponent as DocumentIconSVG } from "assets/document.svg";
import { ReactComponent as DocumentFilledIconSVG } from "assets/documentFilled.svg";
import { ReactComponent as DollarIconSVG } from "assets/dollarIcon.svg";
import { ReactComponent as ErrorStateSVG } from "assets/errorState.svg";
import { ReactComponent as EventNoteIconSVG } from "assets/eventNote.svg";
import { ReactComponent as FarmsIconSVG } from "assets/farms.svg";
import { ReactComponent as FarmsLightSVG } from "assets/farmsLight.svg";
import { ReactComponent as FieldIconSVG } from "assets/fields.svg";
import { ReactComponent as GarbageCanIconSVG } from "assets/garbageCan.svg";
import { ReactComponent as HelpIconSVG } from "assets/help.svg";
import { ReactComponent as RemoveIconSVG } from "assets/remove.svg";
import { ReactComponent as PageNotFoundSVG } from "assets/pageNotFound.svg";
import { ReactComponent as SortBothIcon } from "assets/sortBoth.svg";
import { ReactComponent as SortUpIcon } from "assets/sortUp.svg";
import { ReactComponent as SortDownIcon } from "assets/sortDown.svg";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import { ReactComponent as UsersIconSVG } from "assets/users.svg";

export const iconSize = "22px";

export const iconSpacing = 2;

export const AddIcon = (props: IconProps): ReactElement => (
  <Icon as={AddIconSVG} color="brand.800" {...props} />
);

export const AlertCircle = (props: IconProps): ReactElement => (
  <Icon as={IoAlertCircle} boxSize={iconSize} color="error" {...props} />
);

export const AlertCircleOutline = (props: IconProps): ReactElement => (
  <Icon as={IoAlertCircleOutline} boxSize={iconSize} color="error" {...props} />
);

export const ArrowRight = (props: IconProps): ReactElement => (
  <Icon as={FiArrowRight} boxSize={iconSize} {...props} />
);

export const CalendarIcon = (props: IconProps): ReactElement => (
  <Icon as={CalendarIconSVG} color="charcoal" {...props} />
);

export const Checkmark = (props: IconProps): ReactElement => (
  <Icon as={ImCheckmark} boxSize={iconSize} color="brand.800" {...props} />
);

export const CheckmarkThin = (props: IconProps): ReactElement => (
  <Icon as={CheckMarkIconSVG} boxSize={iconSize} color="brand.800" {...props} />
);

export const CheckmarkCircle = (props: IconProps): ReactElement => (
  <Icon
    as={IoCheckmarkCircle}
    boxSize={iconSize}
    color="brand.800"
    {...props}
  />
);

export const CloseIconCircle = (props: IconProps): ReactElement => (
  <Icon as={CloseIconSVG} boxSize={iconSize} color="brand.800" {...props} />
);

export const CloseIcon = (props: IconProps): ReactElement => (
  <Icon as={IoCloseSharp} boxSize={iconSize} color="brand.800" {...props} />
);

export const DashboardIcon = (props: IconProps): ReactElement => (
  <Icon as={DashboardIconSVG} color="charcoal" boxSize={6} {...props} />
);

export const DocumentIcon = (props: IconProps): ReactElement => (
  <Icon as={DocumentIconSVG} color="charcoal" {...props} />
);

export const DocumentFilledIcon = (props: IconProps): ReactElement => (
  <Icon as={DocumentFilledIconSVG} color="charcoal" boxSize={6} {...props} />
);

export const ErrorStateIcon = (props: IconProps): ReactElement => (
  <Icon as={ErrorStateSVG} minW="400px" minH="400px" {...props} />
);

export const EventNoteIcon = (props: IconProps): ReactElement => (
  <Icon as={EventNoteIconSVG} color="charcoal" {...props} />
);

export const EyeClosed = (props: IconProps): ReactElement => (
  <Icon as={FiEyeOff} boxSize={iconSize} color="charcoal" {...props} />
);

export const EyeOpen = (props: IconProps): ReactElement => (
  <Icon as={FiEye} boxSize={iconSize} color="charcoal" {...props} />
);

export const FarmsIcon = (props: IconProps): ReactElement => (
  <Icon as={FarmsIconSVG} color="charcoal" {...props} />
);

export const FarmsLightIcon = (props: IconProps): ReactElement => (
  <Icon as={FarmsLightSVG} color="charcoal" {...props} />
);

export const FieldIcon = (props: IconProps): ReactElement => (
  <Icon as={FieldIconSVG} color="charcoal" boxSize={iconSize} {...props} />
);

export const GarbageCanIcon = (props: IconProps): ReactElement => (
  <Icon
    as={GarbageCanIconSVG}
    color="errorRead"
    boxSize={iconSize}
    {...props}
  />
);

export const HamburgerIcon = (props: IconProps): ReactElement => (
  <Icon as={GiHamburgerMenu} boxSize={iconSize} color="charcoal" {...props} />
);

export const HelpIcon = (props: IconProps): ReactElement => (
  <Icon as={HelpIconSVG} boxSize={iconSize} color="steelGray" {...props} />
);

export const DollarIcon = (props: IconProps): ReactElement => (
  <Icon as={DollarIconSVG} boxSize="16px" color="charcoal" {...props} />
);

export const RemoveIcon = (props: IconProps): ReactElement => (
  <Icon as={RemoveIconSVG} color="error.700" {...props} />
);

export const PageNotFound = (props: IconProps): ReactElement => (
  <Icon as={PageNotFoundSVG} minW="400px" minH="400px" {...props} />
);

export const Search = (props: IconProps): ReactElement => (
  <Icon as={SearchIcon} boxSize={4} color="charcoal" {...props} />
);
export const SortBoth = (props: IconProps): ReactElement => (
  <Icon as={SortBothIcon} boxSize={6} color="charcoal" {...props} />
);
export const SortUp = (props: IconProps): ReactElement => (
  <Icon as={SortUpIcon} boxSize={6} color="charcoal" {...props} />
);

export const SortDown = (props: IconProps): ReactElement => (
  <Icon as={SortDownIcon} boxSize={6} color="charcoal" {...props} />
);

export const UploadIcon = (props: IconProps): ReactElement => (
  <Icon as={HiOutlineUpload} boxSize={iconSize} color="brand.900" {...props} />
);

export const UsersIcon = (props: IconProps): ReactElement => (
  <Icon as={UsersIconSVG} color="charcoal" boxSize={6} {...props} />
);
