import {
  FarmListDto,
  ProgramYearApi,
  ProgramYearApiAssignFarmsToProgramYearRequest,
  ProgramYearApiListProgramYearsRequest,
  ProgramYearResponse,
} from "api";
import {
  extractResponseData,
  extractResponseDataWithMeta,
  processResponse,
  QueryParams,
  transformQueryParamsForRequest,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

class ProgramYearService {
  private controller = constructApiControllerInstance(ProgramYearApi);

  async getProgramYears(
    request: ProgramYearApiListProgramYearsRequest
  ): Promise<Array<ProgramYearResponse>> {
    const response = await this.controller.listProgramYears(request);

    return extractResponseData(response);
  }

  async getProgramYearFarms(id: string, queryParams?: QueryParams) {
    const response = await this.controller.listProgramYearFarms({
      id,
      ...transformQueryParamsForRequest(queryParams),
    });

    return extractResponseDataWithMeta(response);
  }

  async getProgramYearFarmDocuments(
    programYearId: string,
    farmId: string,
    queryParams?: QueryParams
  ) {
    const response = await this.controller.getProgramYearFarmDocumentsPage({
      id: programYearId,
      farmId,
      farmId2: farmId,
      ...transformQueryParamsForRequest(queryParams),
    });

    return extractResponseDataWithMeta(response);
  }

  async getAddableFarmsForProgramYear(
    id: string,
    queryParams?: QueryParams
  ): Promise<FarmListDto[]> {
    const response = await this.controller.listFarmsNotAssignedToProgramYear({
      id,
      ...transformQueryParamsForRequest(queryParams),
    });

    return extractResponseData(response);
  }

  async assignFarmsToProgramYear(
    request: ProgramYearApiAssignFarmsToProgramYearRequest
  ): Promise<string> {
    return await this.controller
      .assignFarmsToProgramYear(request)
      .then(processResponse);
  }
}

export default ProgramYearService;
