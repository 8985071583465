import { Center, Spinner, Text } from "@chakra-ui/react";
import { InView } from "react-intersection-observer";

const FetchMoreWhenInView = ({
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}: {
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
}) => {
  const displayText = hasNextPage ? "Loading..." : "Nothing more to load";

  return (
    <Center pb={10}>
      <InView
        as="div"
        onChange={(inView) => {
          if (inView && hasNextPage) {
            fetchNextPage();
          }
        }}
      >
        <Text color="steelGray" fontWeight="bold">
          {isFetchingNextPage ? (
            <Spinner size="lg" borderWidth="4px" />
          ) : (
            displayText
          )}
        </Text>
      </InView>
    </Center>
  );
};

export default FetchMoreWhenInView;
