import { useQuery } from "@tanstack/react-query";

import { useCropYearService } from "contexts/ServiceProvider";

export const GET_MONTHLY_AVERAGE_LIST_BY_MONTH_QUERY_KEY =
  "get-monthly-average-list-by-month";

const useGetMonthlyAverageListByMonth = (
  cropYearId?: string,
  month?: APIMonth
) => {
  const cropYearService = useCropYearService();

  return useQuery({
    queryKey: [
      GET_MONTHLY_AVERAGE_LIST_BY_MONTH_QUERY_KEY,
      { id: cropYearId, month },
    ],
    queryFn: () =>
      cropYearId && month
        ? cropYearService.getMonthlyAverageListByMonth({
            id: cropYearId,
            month,
          })
        : Promise.reject("Must provide crop year id and month"),
    enabled: !!cropYearId && !!month,
    gcTime: 0,
  });
};

export default useGetMonthlyAverageListByMonth;
