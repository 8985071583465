import { useDisclosure } from "@chakra-ui/react";

import { AddFarmsToYearButton } from "components/AddFarmsToYear";
import { programs } from "constants/programs";
import { useProgramYear } from "contexts/ProgramYearProvider";
import useAssignFarmToProgramYear from "hooks/api/program-year/useAssignFarmToProgramYear";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import documentProgramPaths from "routes/document-programs/documentProgramPaths";

const AddFarmsToProgramYearButton = ({
  farmIds,
  isDisabled,
  onSuccess,
}: Readonly<{
  farmIds: Array<string>;
  isDisabled: boolean;
  onSuccess(): void;
}>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { program, programYear } = useProgramYear();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const navigate = useNavigateWithQuery();

  const { title } = programs[program];

  const { mutateAsync, isPending } = useAssignFarmToProgramYear(programYear.id);

  const onAddFarms = () => {
    if (farmIds.length > 0) {
      mutateAsync(farmIds)
        .then(() => {
          onSuccess();
          onSuccessToast({
            title: "Farms Added Successfully",
            message: `Selected farms have been added to ${title} ${programYear.year}`,
          });
          onClose();
          navigate(
            documentProgramPaths.fullPaths.allFarms(program, programYear.id)
          );
        })
        .catch(() => {
          onErrorToast({
            title: "Error",
            message: `Selected farms were not added to ${title} ${programYear.year} Program, please try again`,
          });
          onClose();
        });
    }
  };

  return (
    <AddFarmsToYearButton
      programType={title}
      year={programYear.year}
      isDisabled={isDisabled}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onAddFarms={onAddFarms}
      isLoading={isPending}
    />
  );
};

export default AddFarmsToProgramYearButton;
