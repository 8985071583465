import { Program, programs } from "constants/programs";
import { FARM_ID_QUERY_STRING_KEY } from "hooks/useSelectedFarmId";
import { PROGRAM_YEAR_ID_QUERY_STRING_KEY } from "hooks/useSelectedProgramYearId";

const documentProgramPaths = {
  basePath: (program: Program) => `${programs[program].slug}`,
  children: {
    allFarms: "all-farms",
    farmDocuments: "documents",
    addFarms: "add-farms",
  },
  fullPaths: {
    allFarms: (program: Program, programYearId: string) =>
      `/${programs[program].slug}/all-farms?${PROGRAM_YEAR_ID_QUERY_STRING_KEY}=${programYearId}`,
    addFarms: (program: Program, programYearId: string) =>
      `/${programs[program].slug}/add-farms?${PROGRAM_YEAR_ID_QUERY_STRING_KEY}=${programYearId}`,
    farmDocuments: (program: Program, programYearId: string, farmId: string) =>
      `/${programs[program].slug}/documents?${PROGRAM_YEAR_ID_QUERY_STRING_KEY}=${programYearId}&${FARM_ID_QUERY_STRING_KEY}=${farmId}`,
  },
};

export default documentProgramPaths;
