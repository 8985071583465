import { Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const NavigationGroupHeader = ({ children }: PropsWithChildren) => {
  return (
    <Text
      color="charcoal"
      textTransform="uppercase"
      fontWeight="bold"
      fontSize="sm"
      pl={6}
      py={2}
      as="h3"
    >
      {children}
    </Text>
  );
};

export default NavigationGroupHeader;
