import { useCallback, useMemo } from "react";

import { SelectOption } from "components/Select";
import YearSelect from "components/YearSelect";
import { useProgramYear } from "contexts/ProgramYearProvider";
import useGetProgramYears from "hooks/api/program-year/useGetProgramYears";
import useSelectedProgramYearId from "hooks/useSelectedProgramYearId";

const ProgramYearSelect = () => {
  const { program } = useProgramYear();
  const { data, isLoading } = useGetProgramYears(program);
  const [selectedProgramYearId, setSelectedProgramYearId] =
    useSelectedProgramYearId();

  const options: SelectOption[] = useMemo(
    () =>
      data?.map((programYear) => ({
        label: `${programYear.year}`,
        value: programYear.id,
      })) ?? [],
    [data]
  );

  const onSelectOption = useCallback(
    (option: SelectOption | undefined) => {
      if (!option) return;

      setSelectedProgramYearId(option.value);
    },
    [setSelectedProgramYearId]
  );

  return (
    <YearSelect
      options={options}
      yearId={selectedProgramYearId}
      name="programYearSelect"
      isLoading={isLoading}
      setSelectedOption={onSelectOption}
    />
  );
};

export default ProgramYearSelect;
