import { ButtonProps } from "@chakra-ui/react";
import { FarmsIcon } from "icons";
import { ReactElement } from "react";
import adminPaths from "routes/admin/adminPaths";
import NavigationItem from "./NavigationItem";

export const FarmsNavigationItem = (props: ButtonProps): ReactElement => (
  <NavigationItem
    icon={FarmsIcon}
    route={`/${adminPaths.basePath}/${adminPaths.children.farms}`}
    {...props}
  >
    Farms
  </NavigationItem>
);
