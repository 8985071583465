export enum CSV_DOWNLOAD {
  PRICING_DATA = "CSV_DOWNLOAD.PRICING_DATA",
}
export enum CSV_UPLOAD {
  FIELDS = "CSV_UPLOAD.FIELDS",
  ACTUAL_TONS = "CSV_UPLOAD.ACTUAL_TONS",
  FARM_DOCUMENTS = "CSV_UPLOAD.FARM_DOCUMENTS",
}

export enum DAILY_PRICING {
  DAILY_PRICING_BUTTON = "DAILY_PRICING.DAILY_PRICING_BUTTON",
}

export enum DASHBOARD_TABS {
  ALL_FIELDS = "DASHBOARD_TABS.ALL_FIELDS",
}

export enum ADD_PRICING {
  ADD_PRICING_BUTTON = "ADD_PRICING.ADD_PRICING_BUTTON",
}

export enum MANUAL_DELAYED_PRICING {
  MANUAL_DELAYED_PRICING_BUTTON = "MANUAL_DELAYED_PRICING.MANUAL_DELAYED_PRICING_BUTTON",
}

export enum FORWARD_PRICING {
  FORWARD_PRICING_BUTTON = "FORWARD_PRICING.FORWARD_PRICING_BUTTON",
}
