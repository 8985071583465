import { RouteObject } from "react-router";

import documentProgramPaths from "./documentProgramPaths";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import ProgramYearProvider from "contexts/ProgramYearProvider";
import TableLayout from "layouts/TableLayout";
import AddDocumentProgramFarmsPage from "pages/document-programs/AddDocumentProgramFarmsPage";
import DocumentProgramFarmDocumentListPage from "pages/document-programs/DocumentProgramFarmDocumentListPage";
import DocumentProgramFarmListPage from "pages/document-programs/DocumentProgramFarmListPage";
import { documentProgramKeys } from "constants/programs";

const documentProgramRoutes: Array<RouteObject> = documentProgramKeys.map(
  (program) => ({
    path: documentProgramPaths.basePath(program),
    element: (
      <ProtectedRoute>
        <ProgramYearProvider program={program}>
          <TableLayout />
        </ProgramYearProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        path: documentProgramPaths.children.allFarms,
        element: <DocumentProgramFarmListPage />,
      },
      {
        path: documentProgramPaths.children.farmDocuments,
        element: <DocumentProgramFarmDocumentListPage />,
      },
      {
        path: documentProgramPaths.children.addFarms,
        element: <AddDocumentProgramFarmsPage />,
      },
    ],
  })
);

export default documentProgramRoutes;
