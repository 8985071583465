import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { useProgramYearService } from "contexts/ServiceProvider";
import { getNextPage } from "services/apiHelpers";

export const PROGRAM_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY =
  "program-year-farm-document-link";

const useGetProgramYearFarmDocuments = (
  programYearId: string | undefined,
  farmId: string | undefined
) => {
  const service = useProgramYearService();

  return useInfiniteQuery({
    queryKey: [
      PROGRAM_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY,
      { programYearId, farmId },
    ],
    queryFn:
      programYearId && farmId
        ? ({ pageParam = 0 }) =>
            service.getProgramYearFarmDocuments(programYearId, farmId, {
              page: pageParam,
              size: 10,
            })
        : skipToken,
    initialPageParam: 0,
    getNextPageParam: getNextPage,
  });
};

export default useGetProgramYearFarmDocuments;
