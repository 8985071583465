/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddressResponse } from "./address-response";
import { ContactDto } from "./contact-dto";
import { FarmStatusDto } from "./farm-status-dto";
import { LienHolderResponse } from "./lien-holder-response";
import { RegionDto } from "./region-dto";

/**
 *
 * @export
 * @interface FarmDetailResponse
 */
export interface FarmDetailResponse {
  /**
   *
   * @type {string}
   * @memberof FarmDetailResponse
   */
  id: string;
  /**
   *
   * @type {RegionDto}
   * @memberof FarmDetailResponse
   */
  region?: RegionDto;
  /**
   *
   * @type {string}
   * @memberof FarmDetailResponse
   */
  doingBusinessAs: string;
  /**
   *
   * @type {string}
   * @memberof FarmDetailResponse
   */
  farmPrimaryPhoneNumber: string;
  /**
   *
   * @type {ContactDto}
   * @memberof FarmDetailResponse
   */
  mainContact: ContactDto;
  /**
   *
   * @type {string}
   * @memberof FarmDetailResponse
   */
  primaryEmailAddress: string;
  /**
   *
   * @type {AddressResponse}
   * @memberof FarmDetailResponse
   */
  mailingAddress: AddressResponse;
  /**
   *
   * @type {string}
   * @memberof FarmDetailResponse
   */
  stateOfOrganization: FarmDetailResponseStateOfOrganizationEnum;
  /**
   *
   * @type {Array<LienHolderResponse>}
   * @memberof FarmDetailResponse
   */
  lienHolders: Array<LienHolderResponse>;
  /**
   *
   * @type {FarmStatusDto}
   * @memberof FarmDetailResponse
   */
  status: FarmStatusDto;
  /**
   *
   * @type {Array<string>}
   * @memberof FarmDetailResponse
   */
  inPrograms: Array<FarmDetailResponseInProgramsEnum>;
  /**
   *
   * @type {number}
   * @memberof FarmDetailResponse
   */
  entityId?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum FarmDetailResponseStateOfOrganizationEnum {
  Arizona = "ARIZONA",
  Minnesota = "MINNESOTA",
  Nebraska = "NEBRASKA",
  NewMexico = "NEW_MEXICO",
  NorthDakota = "NORTH_DAKOTA",
  SouthDakota = "SOUTH_DAKOTA",
}
/**
 * @export
 * @enum {string}
 */
export enum FarmDetailResponseInProgramsEnum {
  Silage = "SILAGE",
  CornSilage = "CORN_SILAGE",
  DryCorn = "DRY_CORN",
  Hay = "HAY",
  Haylage = "HAYLAGE",
  Manure = "MANURE",
  Wheatlage = "WHEATLAGE",
}
